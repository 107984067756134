<script setup lang="ts">
import { onClickOutside, useFocus, useMagicKeys } from "@vueuse/core";

withDefaults(
  defineProps<{
    displayTotal?: number;
  }>(),
  { displayTotal: 5 },
);

defineEmits<{
  (e: "link-clicked"): void;
}>();

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

// True when the search bar is active and user can type in the search field
const active = ref(false);

// Reference to the search container
const searchContainer = ref(null);
const searchInput = ref();

watch(active, (value) => {
  const { focused } = useFocus(searchInput);

  focused.value = value;
});

// String the user has typed in the search field
const typingQuery = ref("");

// Suggest results will only be shown, when the search bar is active and the user has typed more than three characters in the search field
const showSuggest = computed(() => {
  return typingQuery.value.length >= 3 && active.value;
});

if (import.meta.client) {
  onClickOutside(searchContainer, () => {
    active.value = false;
  });
}

const { enter } = useMagicKeys({ target: searchInput });
if (enter) {
  watch(enter, (value) => {
    if (!value) return;

    active.value = false;
    navigateTo(formatLink("/search?search=" + typingQuery.value));
  });
}
</script>

<template>
  <div class="w-100">
    <div ref="searchContainer" class="relative w-full self-stretch">
      <div class="flex">
        <div
          class="i-sl-search-alternate size-5 xl:size-6 cursor-pointer c-scheppach-primary-700 mr-3"
        ></div>

        <input
          ref="searchInput"
          v-model="typingQuery"
          data-testid="layout-search-input"
          type="text"
          aria-label="Search for products"
          class="grow background-none font-700 focus:outline-none h-full b-b b-scheppach-primary-700"
          @click="active = true"
        />
      </div>

      <div
        v-if="showSuggest"
        data-testid="layout-search-result-box"
        class="absolute min-h-[100px] b-secondary-100 b-t-1 duration-200 left-0 overflow-hidden right-0 rd-b-md shadow-md transition-height w-auto z-1 p-3 bg-scheppach-primary-50"
      >
        <LayoutSearchResult
          :typing-query="typingQuery"
          :display-total="displayTotal"
          @link-clicked="active = false"
        />
      </div>
    </div>
  </div>
</template>
